import React from "react";

const PrivacyPolicy = () => {
  return (

      <>
        <div className="container-fluid pt-5 hero-header mb-5">
          <div className="slider-container">
            <div className="slide2">
              <div className="container pt-5">
                <div className="row g-5 pt-5">
                  <div className="col-lg-8 align-self-center text-center text-lg-start mb-lg-5">
                    <h1 className="display-6 text-white mb-1 animated slideInRight">
                    PRIVACY POLICY
                    </h1>
                    <p><span style={{ color: "#fff" }}>
            <strong>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="subtitle">
                  Last updated <bdt className="question">April 11, 2024</bdt>
                </span>
              </span>
            </strong>
          </span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid py-5">
          <div className="container">
            <div className="row g-5 align-items-center">
              
            <style
        dangerouslySetInnerHTML={{
          __html:
            "\n  [data-custom-class='body'], [data-custom-class='body'] * {\n          background: transparent !important;\n        }\n[data-custom-class='title'], [data-custom-class='title'] * {\n          font-family: Arial !important;\nfont-size: 26px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {\n          font-family: Arial !important;\ncolor: #595959 !important;\nfont-size: 14px !important;\n        }\n[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {\n          font-family: Arial !important;\nfont-size: 19px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {\n          font-family: Arial !important;\nfont-size: 17px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='body_text'], [data-custom-class='body_text'] * {\n          color: #595959 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\n        }\n[data-custom-class='link'], [data-custom-class='link'] * {\n          color: #3030F1 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\nword-break: break-word !important;\n        }\n",
        }}
      />
      <div data-custom-class="body">
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                This privacy notice for{" "}
                <bdt className="question">
                  Rscom Networking Ltd.
                  <bdt className="block-component" />
                </bdt>{" "}
                (<bdt className="block-component" />'<strong>we</strong>', '
                <strong>us</strong>', or '<strong>our</strong>'
                <bdt className="else-block" />
              </span>
              <span data-custom-class="body_text">
                ), describes how and why we might collect, store, use, and/or
                share (<bdt className="block-component" />'
                <strong>process</strong>'
                <bdt className="else-block" />) your information when you use
                our services (<bdt className="block-component" />'
                <strong>Services</strong>'<bdt className="else-block" />
                ), such as when you:
              </span>
            </span>
          </span>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  Visit our website
                  <bdt className="block-component" /> at{" "}
                  <span style={{ color: "rgb(0, 58, 250)" }}>
                    <bdt className="question">
                      <a
                        href="http://www.rscomnetwork.com"
                        data-custom-class="link"
                      >
                        http://www.rscomnetwork.com
                      </a>
                    </bdt>
                  </span>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="statement-end-if-in-editor">
                              , or any website of ours that links to this
                              privacy notice
                            </bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div>
          <bdt className="block-component">
            <span style={{ fontSize: 15 }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </bdt>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    Engage with us in other related ways, including any sales,
                    marketing, or events
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span data-custom-class="body_text">
                  <strong>Questions or concerns?&nbsp;</strong>Reading this
                  privacy notice will help you understand your privacy rights
                  and choices. If you do not agree with our policies and
                  practices, please do not use our Services.
                  <bdt className="block-component" /> If you still have any
                  questions or concerns, please contact us at{" "}
                  <bdt className="question">aras0665yusuf@gmail.com</bdt>.
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <strong>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
              </span>
            </strong>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  <em>
                    This summary provides key points from our privacy notice,
                    but you can find out more details about any of these topics
                    by clicking the link following each key point or by using
                    our&nbsp;
                  </em>
                </strong>
              </span>
            </span>
            <a data-custom-class="link" href="#toc">
              <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <strong>
                    <em>table of contents</em>
                  </strong>
                </span>
              </span>
            </a>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  <em>&nbsp;below to find the section you are looking for.</em>
                </strong>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>What personal information do we process?</strong> When
                you visit, use, or navigate our Services, we may process
                personal information depending on how you interact with us and
                the Services, the choices you make, and the products and
                features you use. Learn more about&nbsp;
              </span>
            </span>
            <a data-custom-class="link" href="#personalinfo">
              <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  personal information you disclose to us
                </span>
              </span>
            </a>
            <span data-custom-class="body_text">.</span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  Do we process any sensitive personal information?
                </strong>{" "}
                <bdt className="block-component" />
                We do not process sensitive personal information.
                <bdt className="else-block" />
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  Do we receive any information from third parties?
                </strong>{" "}
                <bdt className="block-component" />
                We may receive information from public databases, marketing
                partners, social media platforms, and other outside sources.
                Learn more about&nbsp;
              </span>
            </span>
            <a data-custom-class="link" href="#othersources">
              <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  information collected from other sources
                </span>
              </span>
            </a>
            <span data-custom-class="body_text">.</span>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>How do we process your information?</strong> We process
                your information to provide, improve, and administer our
                Services, communicate with you, for security and fraud
                prevention, and to comply with law. We may also process your
                information for other purposes with your consent. We process
                your information only when we have a valid legal reason to do
                so. Learn more about&nbsp;
              </span>
            </span>
            <a data-custom-class="link" href="#infouse">
              <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  how we process your information
                </span>
              </span>
            </a>
            <span data-custom-class="body_text">.</span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  In what situations and with which{" "}
                  <bdt className="block-component" />
                  parties do we share personal information?
                </strong>{" "}
                We may share information in specific situations and with
                specific <bdt className="block-component" />
                third parties. Learn more about&nbsp;
              </span>
            </span>
            <a data-custom-class="link" href="#whoshare">
              <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  when and with whom we share your personal information
                </span>
              </span>
            </a>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                .<bdt className="block-component" />
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>What are your rights?</strong> Depending on where you
                are located geographically, the applicable privacy law may mean
                you have certain rights regarding your personal information.
                Learn more about&nbsp;
              </span>
            </span>
            <a data-custom-class="link" href="#privacyrights">
              <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                <span data-custom-class="body_text">your privacy rights</span>
              </span>
            </a>
            <span data-custom-class="body_text">.</span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>How do you exercise your rights?</strong> The easiest
                way to exercise your rights is by{" "}
                <bdt className="block-component">submitting a&nbsp;</bdt>
              </span>
            </span>
            <a
              data-custom-class="link"
              href="https://app.termly.io/notify/4e09e410-9f8c-4e8e-a4c9-8be480e881ca"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  data subject access request
                </span>
              </span>
            </a>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />, or by contacting us. We
                will consider and act upon any request in accordance with
                applicable data protection laws.
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                Want to learn more about what we do with any information we
                collect?&nbsp;
              </span>
            </span>
            <a data-custom-class="link" href="#toc">
              <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  Review the privacy notice in full
                </span>
              </span>
            </a>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">.</span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div id="toc" style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
                  </strong>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#infocollect">
                <span style={{ color: "rgb(0, 58, 250)" }}>
                  1. WHAT INFORMATION DO WE COLLECT?
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#infouse">
                <span style={{ color: "rgb(0, 58, 250)" }}>
                  2. HOW DO WE PROCESS YOUR INFORMATION?
                  <bdt className="block-component" />
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#legalbases">
                <span style={{ color: "rgb(0, 58, 250)" }}>
                  3.{" "}
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(0, 58, 250)" }}>
                      WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                      INFORMATION?
                    </span>
                  </span>
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(0, 58, 250)" }}>
                <a data-custom-class="link" href="#whoshare">
                  4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </a>
              </span>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
              <a data-custom-class="link" href="#3pwebsites">
                5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
                <bdt className="statement-end-if-in-editor" />
              </a>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt className="block-component" />
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="block-component" />
                        </span>
                      </span>
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#inforetain">
                <span style={{ color: "rgb(0, 58, 250)" }}>
                  6. HOW LONG DO WE KEEP YOUR INFORMATION?
                </span>
              </a>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="block-component" />
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#infominors">
                <span style={{ color: "rgb(0, 58, 250)" }}>
                  7. DO WE COLLECT INFORMATION FROM MINORS?
                </span>
              </a>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(0, 58, 250)" }}>
                <a data-custom-class="link" href="#privacyrights">
                  8. WHAT ARE YOUR PRIVACY RIGHTS?
                </a>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#DNT">
                <span style={{ color: "rgb(0, 58, 250)" }}>
                  9. CONTROLS FOR DO-NOT-TRACK FEATURES
                  <bdt className="block-component" />
                </span>
                <bdt className="block-component">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text" />
                  </span>
                </bdt>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#DNT">
              <bdt className="block-component">
                <span style={{ fontSize: 15 }} />
              </bdt>
              <bdt className="block-component" />
              <bdt className="block-component" />
              <bdt className="block-component" />
              <bdt className="block-component" />
              <bdt className="block-component" />
              <bdt className="block-component" />
              <bdt className="block-component" />
              <bdt className="block-component" />
              <bdt className="block-component" />
            </a>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#DNT">
              <span style={{ fontSize: 15 }} />
            </a>
            <a data-custom-class="link" href="#policyupdates">
              <span style={{ color: "rgb(0, 58, 250)" }}>
                10. DO WE MAKE UPDATES TO THIS NOTICE?
              </span>
            </a>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#contact">
              <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </span>
            </a>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <a data-custom-class="link" href="#request">
              <span style={{ color: "rgb(0, 58, 250)" }}>
                12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </span>
            </a>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div id="infocollect" style={{ lineHeight: "1.5" }}>
            <span style={{ color: "rgb(0, 0, 0)" }}>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
                <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                  <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                    <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>
                        <span data-custom-class="heading_1">
                          1. WHAT INFORMATION DO WE COLLECT?
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div id="personalinfo" style={{ lineHeight: "1.5" }}>
            <span
              data-custom-class="heading_2"
              style={{ color: "rgb(0, 0, 0)" }}
            >
              <span style={{ fontSize: 15 }}>
                <strong>Personal information you disclose to us</strong>
              </span>
            </span>
          </div>
          <div>
            <div>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <strong>
                            <em>In Short:</em>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <strong>
                            <em>&nbsp;</em>
                          </strong>
                          <em>
                            We collect personal information that you provide to
                            us.
                          </em>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  We collect personal information that you voluntarily provide
                  to us when you{" "}
                  <span style={{ fontSize: 15 }}>
                    <bdt className="block-component" />
                  </span>
                </span>
                <span data-custom-class="body_text">
                  express an interest in obtaining information about us or our
                  products and Services, when you participate in activities on
                  the Services, or otherwise when you contact us.
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div id="sensitiveinfo" style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>Sensitive Information.</strong>{" "}
                <bdt className="block-component" />
                We do not process sensitive information.
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="else-block" />
              </span>
            </span>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component">
                        <bdt className="block-component" />
                      </bdt>
                    </span>
                  </span>
                </span>
              </span>
              <bdt className="block-component" />
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  All personal information that you provide to us must be true,
                  complete, and accurate, and you must notify us of any changes
                  to such personal information.
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                        <span data-custom-class="body_text">
                          <bdt className="statement-end-if-in-editor">
                            <bdt className="block-component" />
                          </bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div id="othersources" style={{ lineHeight: "1.5" }}>
            <span
              data-custom-class="heading_2"
              style={{ color: "rgb(0, 0, 0)" }}
            >
              <span style={{ fontSize: 15 }}>
                <strong>Information collected from other sources</strong>
              </span>
            </span>
          </div>
          <div>
            <div>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <strong>
                            <em>In Short:&nbsp;</em>
                          </strong>
                          <em>
                            We may collect limited data from public databases,
                            marketing partners,{" "}
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ fontSize: 15 }}>
                                <em>
                                  <span data-custom-class="body_text">
                                    <bdt className="block-component" />
                                  </span>
                                </em>
                              </span>
                            </span>
                            and other outside sources.
                          </em>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  In order to enhance our ability to provide relevant marketing,
                  offers, and services to you and update our records, we may
                  obtain information about you from other sources, such as
                  public databases, joint marketing partners, affiliate
                  programs, data providers,
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                    &nbsp;
                  </span>
                  and from other third parties. This information includes
                  mailing addresses, job titles, email addresses, phone numbers,
                  intent data (or user <bdt className="block-component" />
                  behaviour
                  <bdt className="else-block" /> data), Internet Protocol (IP)
                  addresses, social media profiles, social media URLs, and
                  custom profiles, for purposes of targeted advertising and
                  event promotion.
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                              <bdt className="block-component" />
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="block-component">
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: 15,
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <bdt className="statement-end-if-in-editor">
                                        <bdt className="statement-end-if-in-editor" />
                                      </bdt>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div id="infouse" style={{ lineHeight: "1.5" }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>
                        <span data-custom-class="heading_1">
                          2. HOW DO WE PROCESS YOUR INFORMATION?
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <strong>
                            <em>In Short:&nbsp;</em>
                          </strong>
                          <em>
                            We process your information to provide, improve, and
                            administer our Services, communicate with you, for
                            security and fraud prevention, and to comply with
                            law. We may also process your information for other
                            purposes with your consent.
                          </em>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <strong>
                    We process your personal information for a variety of
                    reasons, depending on how you interact with our Services,
                    including:
                  </strong>
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          To deliver and facilitate delivery of services to the
                          user.&nbsp;
                        </strong>
                        We may process your information to provide you with the
                        requested service.
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <bdt className="statement-end-if-in-editor" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </div>
                <ul>
                  <li style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <strong>
                            To respond to user inquiries/offer support to
                            users.&nbsp;
                          </strong>
                          We may process your information to respond to your
                          inquiries and solve any potential issues you might
                          have with the requested service.
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                    </span>
                  </li>
                </ul>
                <div style={{ lineHeight: "1.5" }}>
                  <bdt className="block-component">
                    <span style={{ fontSize: 15 }} />
                  </bdt>
                  <div style={{ lineHeight: "1.5" }}>
                    <bdt className="block-component">
                      <span style={{ fontSize: 15 }} />
                    </bdt>
                  </div>
                  <ul>
                    <li style={{ lineHeight: "1.5" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <strong>
                              To send administrative information to you.&nbsp;
                            </strong>
                            We may process your information to send you details
                            about our products and services, changes to our
                            terms and policies, and other similar information.
                            <span
                              style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                            >
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span data-custom-class="body_text">
                                        <bdt className="statement-end-if-in-editor" />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style={{ lineHeight: "1.5" }}>
                    <bdt className="block-component">
                      <span style={{ fontSize: 15 }} />
                    </bdt>
                    <div style={{ lineHeight: "1.5" }}>
                      <bdt className="block-component">
                        <span style={{ fontSize: 15 }}>
                          <span data-custom-class="body_text" />
                        </span>
                      </bdt>
                    </div>
                    <ul>
                      <li style={{ lineHeight: "1.5" }}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15 }}>
                            <strong>
                              To <bdt className="block-component" />
                              fulfil
                              <bdt className="else-block" /> and manage your
                              orders.
                            </strong>{" "}
                            We may process your information to{" "}
                            <bdt className="block-component" />
                            fulfil
                            <bdt className="else-block" /> and manage your
                            orders, payments, returns, and exchanges made
                            through the Services.
                          </span>
                        </span>
                        <bdt className="statement-end-if-in-editor">
                          <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text" />
                          </span>
                        </bdt>
                      </li>
                    </ul>
                    <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                      <bdt className="block-component">
                        <span style={{ fontSize: 15 }} />
                      </bdt>
                    </p>
                    <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                      <bdt className="block-component">
                        <span style={{ fontSize: 15 }} />
                      </bdt>
                    </p>
                    <ul>
                      <li style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <strong>
                                  To enable user-to-user communications.&nbsp;
                                </strong>
                                We may process your information if you choose to
                                use any of our offerings that allow for
                                communication with another user.
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span data-custom-class="body_text">
                                              <bdt className="statement-end-if-in-editor" />
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </li>
                    </ul>
                    <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                      <bdt className="block-component" />
                    </p>
                    <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                      <bdt className="block-component" />
                    </p>
                    <ul>
                      <li style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <strong>To request feedback.&nbsp;</strong>We
                                may process your information when necessary to
                                request feedback and to contact you about your
                                use of our Services.
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span data-custom-class="body_text">
                                              <bdt className="statement-end-if-in-editor" />
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </li>
                    </ul>
                    <div style={{ lineHeight: "1.5" }}>
                      <bdt className="block-component">
                        <span style={{ fontSize: 15 }}>
                          <span data-custom-class="body_text" />
                        </span>
                      </bdt>
                      <div style={{ lineHeight: "1.5" }}>
                        <bdt className="block-component">
                          <span style={{ fontSize: 15 }} />
                        </bdt>
                      </div>
                      <ul>
                        <li style={{ lineHeight: "1.5" }}>
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span
                              style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                            >
                              <span data-custom-class="body_text">
                                <strong>
                                  To send you marketing and promotional
                                  communications.&nbsp;
                                </strong>
                                We may process the personal information you send
                                to us for our marketing purposes, if this is in
                                accordance with your marketing preferences. You
                                can opt out of our marketing emails at any time.
                                For more information, see{" "}
                                <bdt className="block-component" />'
                                <bdt className="else-block" />
                              </span>
                            </span>
                          </span>
                          <a data-custom-class="link" href="#privacyrights">
                            <span
                              style={{ fontSize: 15, color: "rgb(0, 58, 250)" }}
                            >
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(0, 58, 250)",
                                }}
                              >
                                <span data-custom-class="body_text">
                                  WHAT ARE YOUR PRIVACY RIGHTS?
                                </span>
                              </span>
                            </span>
                          </a>
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span
                              style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                            >
                              <span data-custom-class="body_text">
                                <bdt className="block-component" />'
                                <bdt className="else-block" /> below.
                              </span>
                              <span data-custom-class="body_text">
                                <bdt className="statement-end-if-in-editor" />
                              </span>
                            </span>
                          </span>
                        </li>
                      </ul>
                      <div style={{ lineHeight: "1.5" }}>
                        <bdt className="block-component">
                          <span style={{ fontSize: 15 }} />
                        </bdt>
                        <div style={{ lineHeight: "1.5" }}>
                          <span style={{ fontSize: 15 }}>
                            <bdt className="block-component">
                              <span data-custom-class="body_text" />
                            </bdt>
                          </span>
                        </div>
                        <ul>
                          <li style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15 }}>
                              <span data-custom-class="body_text">
                                <strong>
                                  To deliver targeted advertising to you.
                                </strong>{" "}
                                We may process your information to develop and
                                display <bdt className="block-component" />
                                personalised
                                <bdt className="else-block" /> content and
                                advertising tailored to your interests,
                                location, and more.
                                <bdt className="block-component" />
                              </span>
                            </span>
                          </li>
                        </ul>
                        <div style={{ lineHeight: "1.5" }}>
                          <bdt className="block-component">
                            <span style={{ fontSize: 15 }}>
                              <span data-custom-class="body_text" />
                            </span>
                          </bdt>
                          <div style={{ lineHeight: "1.5" }}>
                            <bdt className="block-component">
                              <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text" />
                              </span>
                            </bdt>
                            <div style={{ lineHeight: "1.5" }}>
                              <bdt className="block-component">
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text" />
                                </span>
                              </bdt>
                            </div>
                            <ul>
                              <li style={{ lineHeight: "1.5" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: 15 }}>
                                    <strong>To protect our Services.</strong> We
                                    may process your information as part of our
                                    efforts to keep our Services safe and
                                    secure, including fraud monitoring and
                                    prevention.
                                  </span>
                                </span>
                                <bdt className="statement-end-if-in-editor">
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text" />
                                  </span>
                                </bdt>
                              </li>
                            </ul>
                            <div style={{ lineHeight: "1.5" }}>
                              <bdt className="block-component">
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text" />
                                </span>
                              </bdt>
                              <div style={{ lineHeight: "1.5" }}>
                                <bdt className="block-component">
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text" />
                                  </span>
                                </bdt>
                                <div style={{ lineHeight: "1.5" }}>
                                  <bdt className="block-component">
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text" />
                                    </span>
                                  </bdt>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <bdt className="block-component">
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text" />
                                      </span>
                                    </bdt>
                                  </div>
                                  <ul>
                                    <li style={{ lineHeight: "1.5" }}>
                                      <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15 }}>
                                          <strong>
                                            To identify usage trends.
                                          </strong>{" "}
                                          We may process information about how
                                          you use our Services to better
                                          understand how they are being used so
                                          we can improve them.
                                        </span>
                                      </span>
                                      <bdt className="statement-end-if-in-editor">
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text" />
                                        </span>
                                      </bdt>
                                    </li>
                                  </ul>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <bdt className="block-component">
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text" />
                                      </span>
                                    </bdt>
                                    <div style={{ lineHeight: "1.5" }}>
                                      <bdt className="block-component">
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text" />
                                        </span>
                                      </bdt>
                                    </div>
                                    <ul>
                                      <li style={{ lineHeight: "1.5" }}>
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text">
                                            <strong>
                                              To determine the effectiveness of
                                              our marketing and promotional
                                              campaigns.
                                            </strong>{" "}
                                            We may process your information to
                                            better understand how to provide
                                            marketing and promotional campaigns
                                            that are most relevant to you.
                                            <bdt className="statement-end-if-in-editor" />
                                          </span>
                                        </span>
                                      </li>
                                    </ul>
                                    <div style={{ lineHeight: "1.5" }}>
                                      <bdt className="block-component">
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text" />
                                        </span>
                                      </bdt>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                      </div>
                                      <ul>
                                        <li style={{ lineHeight: "1.5" }}>
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                              <strong>
                                                To save or protect an
                                                individual's vital interest.
                                              </strong>{" "}
                                              We may process your information
                                              when necessary to save or protect
                                              an individual’s vital interest,
                                              such as to prevent harm.
                                            </span>
                                          </span>
                                          <bdt className="statement-end-if-in-editor">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                        </li>
                                      </ul>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <br />
                                      </div>
                                      <div
                                        id="legalbases"
                                        style={{ lineHeight: "1.5" }}
                                      >
                                        <strong>
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="heading_1">
                                              3. WHAT LEGAL BASES DO WE RELY ON
                                              TO PROCESS YOUR INFORMATION?
                                            </span>
                                          </span>
                                        </strong>
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <br />
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <em>
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                              <strong>In Short:&nbsp;</strong>We
                                              only process your personal
                                              information when we believe it is
                                              necessary and we have a valid
                                              legal reason (i.e.
                                              <bdt className="block-component" />{" "}
                                              legal basis) to do so under
                                              applicable law, like with your
                                              consent, to comply with laws, to
                                              provide you with services to enter
                                              into or{" "}
                                              <bdt className="block-component" />
                                              fulfil
                                              <bdt className="else-block" /> our
                                              contractual obligations, to
                                              protect your rights, or to{" "}
                                              <bdt className="block-component" />
                                              fulfil
                                              <bdt className="else-block" /> our
                                              legitimate business interests.
                                            </span>
                                          </span>
                                        </em>
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <br />
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text">
                                            <bdt className="block-component" />
                                          </span>
                                          <span data-custom-class="body_text">
                                            <bdt className="block-component" />
                                          </span>
                                        </span>
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text">
                                            The General Data Protection
                                            Regulation (GDPR) and UK GDPR
                                            require us to explain the valid
                                            legal bases we rely on in order to
                                            process your personal information.
                                            As such, we may rely on the
                                            following legal bases to process
                                            your personal information:
                                          </span>
                                        </span>
                                      </div>
                                      <ul>
                                        <li style={{ lineHeight: "1.5" }}>
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                              <strong>Consent.&nbsp;</strong>We
                                              may process your information if
                                              you have given us permission (i.e.
                                              <bdt className="block-component" />{" "}
                                              consent) to use your personal
                                              information for a specific
                                              purpose. You can withdraw your
                                              consent at any time. Learn more
                                              about&nbsp;
                                            </span>
                                          </span>
                                          <a
                                            data-custom-class="link"
                                            href="#withdrawconsent"
                                          >
                                            <span
                                              style={{
                                                color: "rgb(0, 58, 250)",
                                                fontSize: 15,
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                withdrawing your consent
                                              </span>
                                            </span>
                                          </a>
                                          <span data-custom-class="body_text">
                                            .
                                          </span>
                                        </li>
                                      </ul>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                      </div>
                                      <ul>
                                        <li style={{ lineHeight: "1.5" }}>
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                              <strong>
                                                Performance of a Contract.
                                              </strong>{" "}
                                              We may process your personal
                                              information when we believe it is
                                              necessary to{" "}
                                              <bdt className="block-component" />
                                              fulfil
                                              <bdt className="else-block" /> our
                                              contractual obligations to you,
                                              including providing our Services
                                              or at your request prior to
                                              entering into a contract with you.
                                            </span>
                                          </span>
                                          <bdt className="statement-end-if-in-editor">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                        </li>
                                      </ul>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                      </div>
                                      <ul>
                                        <li style={{ lineHeight: "1.5" }}>
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                              <strong>
                                                Legitimate Interests.
                                              </strong>{" "}
                                              We may process your information
                                              when we believe it is reasonably
                                              necessary to achieve our
                                              legitimate business interests and
                                              those interests do not outweigh
                                              your interests and fundamental
                                              rights and freedoms. For example,
                                              we may process your personal
                                              information for some of the
                                              purposes described in order to:
                                            </span>
                                          </span>
                                        </li>
                                      </ul>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: 15 }}>
                                            <bdt className="block-component" />
                                          </span>
                                        </span>
                                      </div>
                                      <ul style={{ marginLeft: 40 }}>
                                        <li style={{ lineHeight: "1.5" }}>
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                              Send users information about
                                              special offers and discounts on
                                              our products and services
                                              <bdt className="statement-end-if-in-editor" />
                                            </span>
                                          </span>
                                        </li>
                                      </ul>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: 15 }}>
                                            <bdt className="block-component" />
                                          </span>
                                        </span>
                                      </div>
                                      <ul style={{ marginLeft: 40 }}>
                                        <li style={{ lineHeight: "1.5" }}>
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                              Develop and display{" "}
                                              <bdt className="block-component" />
                                              personalised
                                              <bdt className="else-block" /> and
                                              relevant advertising content for
                                              our users
                                              <bdt className="statement-end-if-in-editor" />
                                            </span>
                                          </span>
                                        </li>
                                      </ul>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: 15 }}>
                                            <bdt className="block-component" />
                                          </span>
                                        </span>
                                      </div>
                                      <ul style={{ marginLeft: 40 }}>
                                        <li style={{ lineHeight: "1.5" }}>
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                              <bdt className="block-component" />
                                              Analyse
                                              <bdt className="else-block" /> how
                                              our Services are used so we can
                                              improve them to engage and retain
                                              users
                                              <bdt className="statement-end-if-in-editor" />
                                            </span>
                                          </span>
                                        </li>
                                      </ul>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: 15 }}>
                                            <bdt className="block-component" />
                                          </span>
                                        </span>
                                      </div>
                                      <ul style={{ marginLeft: 40 }}>
                                        <li style={{ lineHeight: "1.5" }}>
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                              Support our marketing activities
                                              <bdt className="statement-end-if-in-editor" />
                                            </span>
                                          </span>
                                        </li>
                                      </ul>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: 15 }}>
                                            <bdt className="block-component" />
                                          </span>
                                        </span>
                                      </div>
                                      <ul style={{ marginLeft: 40 }}>
                                        <li style={{ lineHeight: "1.5" }}>
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                              Diagnose problems and/or prevent
                                              fraudulent activities
                                              <bdt className="statement-end-if-in-editor" />
                                            </span>
                                          </span>
                                        </li>
                                      </ul>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: 15 }}>
                                            <bdt className="block-component" />
                                          </span>
                                        </span>
                                      </div>
                                      <ul style={{ marginLeft: 40 }}>
                                        <li style={{ lineHeight: "1.5" }}>
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                              Understand how our users use our
                                              products and services so we can
                                              improve user experience
                                              <bdt className="statement-end-if-in-editor" />
                                            </span>
                                          </span>
                                        </li>
                                      </ul>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: 15 }}>
                                            <bdt className="block-component" />
                                          </span>
                                        </span>
                                        <bdt className="statement-end-if-in-editor">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                      </div>
                                      <ul>
                                        <li style={{ lineHeight: "1.5" }}>
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                              <strong>
                                                Legal Obligations.
                                              </strong>{" "}
                                              We may process your information
                                              where we believe it is necessary
                                              for compliance with our legal
                                              obligations, such as to cooperate
                                              with a law enforcement body or
                                              regulatory agency, exercise or
                                              defend our legal rights, or
                                              disclose your information as
                                              evidence in litigation in which we
                                              are involved.
                                              <bdt className="statement-end-if-in-editor" />
                                              <br />
                                            </span>
                                          </span>
                                        </li>
                                      </ul>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                      </div>
                                      <ul>
                                        <li style={{ lineHeight: "1.5" }}>
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                              <strong>Vital Interests.</strong>{" "}
                                              We may process your information
                                              where we believe it is necessary
                                              to protect your vital interests or
                                              the vital interests of a third
                                              party, such as situations
                                              involving potential threats to the
                                              safety of any person.
                                            </span>
                                          </span>
                                          <bdt className="statement-end-if-in-editor">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                        </li>
                                      </ul>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: 15 }}>
                                            <bdt className="block-component">
                                              <bdt className="block-component" />
                                            </bdt>
                                          </span>
                                        </span>
                                        <bdt className="statement-end-if-in-editor">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <br />
                                      </div>
                                      <div
                                        id="whoshare"
                                        style={{ lineHeight: "1.5" }}
                                      >
                                        <span
                                          style={{
                                            color: "rgb(127, 127, 127)",
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                              fontSize: 15,
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span
                                                  id="control"
                                                  style={{
                                                    color: "rgb(0, 0, 0)",
                                                  }}
                                                >
                                                  <strong>
                                                    <span data-custom-class="heading_1">
                                                      4. WHEN AND WITH WHOM DO
                                                      WE SHARE YOUR PERSONAL
                                                      INFORMATION?
                                                    </span>
                                                  </strong>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <br />
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <strong>
                                                <em>In Short:</em>
                                              </strong>
                                              <em>
                                                &nbsp;We may share information
                                                in specific situations described
                                                in this section and/or with the
                                                following{" "}
                                                <bdt className="block-component" />
                                                third parties.
                                              </em>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <bdt className="block-component" />
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <br />
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text">
                                            We{" "}
                                            <bdt className="block-component" />
                                            may need to share your personal
                                            information in the following
                                            situations:
                                          </span>
                                        </span>
                                      </div>
                                      <ul>
                                        <li style={{ lineHeight: "1.5" }}>
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                              <strong>
                                                Business Transfers.
                                              </strong>{" "}
                                              We may share or transfer your
                                              information in connection with, or
                                              during negotiations of, any
                                              merger, sale of company assets,
                                              financing, or acquisition of all
                                              or a portion of our business to
                                              another company.
                                            </span>
                                          </span>
                                        </li>
                                      </ul>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: 15 }} />
                                        </bdt>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                              <bdt className="block-component" />
                                            </span>
                                          </span>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <span style={{ fontSize: 15 }}>
                                              <bdt className="block-component">
                                                <span data-custom-class="body_text" />
                                              </bdt>
                                            </span>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <bdt className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </bdt>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <bdt className="block-component">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </bdt>
                                              </div>
                                              <ul>
                                                <li
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <strong>
                                                        Other Users.
                                                      </strong>{" "}
                                                      When you share personal
                                                      information
                                                      <bdt className="block-component" />{" "}
                                                      or otherwise interact with
                                                      public areas of the
                                                      Services, such personal
                                                      information may be viewed
                                                      by all users and may be
                                                      publicly made available
                                                      outside the Services in
                                                      perpetuity.
                                                      <bdt className="block-component" />{" "}
                                                      Similarly, other users
                                                      will be able to view
                                                      descriptions of your
                                                      activity, communicate with
                                                      you within our Services,
                                                      and view your profile.
                                                      <bdt className="statement-end-if-in-editor" />
                                                    </span>
                                                  </span>
                                                </li>
                                              </ul>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <bdt className="block-component">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </bdt>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <bdt className="block-component">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <strong>
                                                    <span
                                                      id="3pwebsites"
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="heading_1">
                                                        5. WHAT IS OUR STANCE ON
                                                        THIRD-PARTY WEBSITES?
                                                      </span>
                                                    </span>
                                                  </strong>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <strong>
                                                        <em>In Short:</em>
                                                      </strong>
                                                      <em>
                                                        &nbsp;We are not
                                                        responsible for the
                                                        safety of any
                                                        information that you
                                                        share with third parties
                                                        that we may link to or
                                                        who advertise on our
                                                        Services, but are not
                                                        affiliated with, our
                                                        Services.
                                                      </em>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      The Services
                                                      <bdt className="block-component" />{" "}
                                                      may link to third-party
                                                      websites, online services,
                                                      or mobile applications
                                                      and/or contain
                                                      advertisements from third
                                                      parties that are not
                                                      affiliated with us and
                                                      which may link to other
                                                      websites, services, or
                                                      applications. Accordingly,
                                                      we do not make any
                                                      guarantee regarding any
                                                      such third parties, and we
                                                      will not be liable for any
                                                      loss or damage caused by
                                                      the use of such
                                                      third-party websites,
                                                      services, or applications.
                                                      The inclusion of a link
                                                      towards a third-party
                                                      website, service, or
                                                      application does not imply
                                                      an endorsement by us. We
                                                      cannot guarantee the
                                                      safety and privacy of data
                                                      you provide to any third
                                                      parties. Any data
                                                      collected by third parties
                                                      is not covered by this
                                                      privacy notice. We are not
                                                      responsible for the
                                                      content or privacy and
                                                      security practices and
                                                      policies of any third
                                                      parties, including other
                                                      websites, services, or
                                                      applications that may be
                                                      linked to or from the
                                                      Services. You should
                                                      review the policies of
                                                      such third parties and
                                                      contact them directly to
                                                      respond to your questions.
                                                    </span>
                                                  </span>
                                                  <bdt className="statement-end-if-in-editor">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                      fontSize: 15,
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <bdt className="block-component">
                                                              <span data-custom-class="heading_1" />
                                                            </bdt>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: 15,
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <bdt className="block-component" />
                                                                  </span>
                                                                  <bdt className="block-component">
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                  </bdt>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  id="inforetain"
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        "rgb(127, 127, 127)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            id="control"
                                                            style={{
                                                              color:
                                                                "rgb(0, 0, 0)",
                                                            }}
                                                          >
                                                            <strong>
                                                              <span data-custom-class="heading_1">
                                                                6. HOW LONG DO
                                                                WE KEEP YOUR
                                                                INFORMATION?
                                                              </span>
                                                            </strong>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <strong>
                                                          <em>
                                                            In Short:&nbsp;
                                                          </em>
                                                        </strong>
                                                        <em>
                                                          We keep your
                                                          information for as
                                                          long as necessary to{" "}
                                                          <bdt className="block-component" />
                                                          fulfil
                                                          <bdt className="else-block" />{" "}
                                                          the purposes outlined
                                                          in this privacy notice
                                                          unless otherwise
                                                          required by law.
                                                        </em>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        We will only keep your
                                                        personal information for
                                                        as long as it is
                                                        necessary for the
                                                        purposes set out in this
                                                        privacy notice, unless a
                                                        longer retention period
                                                        is required or permitted
                                                        by law (such as tax,
                                                        accounting, or other
                                                        legal requirements).
                                                        <bdt className="block-component" />{" "}
                                                        No purpose in this
                                                        notice will require us
                                                        keeping your personal
                                                        information for longer
                                                        than{" "}
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component" />
                                                              <bdt className="question">
                                                                90 days
                                                              </bdt>
                                                              <bdt className="statement-end-if-in-editor" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                        .
                                                      </span>
                                                      &nbsp;
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        When we have no ongoing
                                                        legitimate business need
                                                        to process your personal
                                                        information, we will
                                                        either delete or{" "}
                                                        <bdt className="block-component" />
                                                        anonymise
                                                        <bdt className="else-block" />{" "}
                                                        such information, or, if
                                                        this is not possible
                                                        (for example, because
                                                        your personal
                                                        information has been
                                                        stored in backup
                                                        archives), then we will
                                                        securely store your
                                                        personal information and
                                                        isolate it from any
                                                        further processing until
                                                        deletion is possible.
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <bdt className="block-component" />
                                                        </span>
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  id="infominors"
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        "rgb(127, 127, 127)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            id="control"
                                                            style={{
                                                              color:
                                                                "rgb(0, 0, 0)",
                                                            }}
                                                          >
                                                            <strong>
                                                              <span data-custom-class="heading_1">
                                                                7. DO WE COLLECT
                                                                INFORMATION FROM
                                                                MINORS?
                                                              </span>
                                                            </strong>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <strong>
                                                          <em>In Short:</em>
                                                        </strong>
                                                        <em>
                                                          &nbsp;We do not
                                                          knowingly collect data
                                                          from or market to{" "}
                                                          <bdt className="block-component" />
                                                          children under 18
                                                          years of age
                                                          <bdt className="else-block" />
                                                          .
                                                        </em>
                                                        <bdt className="block-component" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        We do not knowingly
                                                        solicit data from or
                                                        market to children under
                                                        18 years of age. By
                                                        using the Services, you
                                                        represent that you are
                                                        at least 18 or that you
                                                        are the parent or
                                                        guardian of such a minor
                                                        and consent to such
                                                        minor dependent’s use of
                                                        the Services. If we
                                                        learn that personal
                                                        information from users
                                                        less than 18 years of
                                                        age has been collected,
                                                        we will deactivate the
                                                        account and take
                                                        reasonable measures to
                                                        promptly delete such
                                                        data from our records.
                                                        If you become aware of
                                                        any data we may have
                                                        collected from children
                                                        under age 18, please
                                                        contact us at{" "}
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                            <bdt className="question">
                                                              __________
                                                            </bdt>
                                                            <bdt className="else-block" />
                                                          </span>
                                                        </span>
                                                        .
                                                      </span>
                                                      <span data-custom-class="body_text">
                                                        <bdt className="else-block">
                                                          <bdt className="block-component" />
                                                        </bdt>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  id="privacyrights"
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        "rgb(127, 127, 127)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            id="control"
                                                            style={{
                                                              color:
                                                                "rgb(0, 0, 0)",
                                                            }}
                                                          >
                                                            <strong>
                                                              <span data-custom-class="heading_1">
                                                                8. WHAT ARE YOUR
                                                                PRIVACY RIGHTS?
                                                              </span>
                                                            </strong>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <strong>
                                                          <em>In Short:</em>
                                                        </strong>
                                                        <em>
                                                          &nbsp;
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <em>
                                                                  <bdt className="block-component" />
                                                                </em>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          In some regions, such
                                                          as{" "}
                                                          <bdt className="block-component" />
                                                          the European Economic
                                                          Area (EEA), United
                                                          Kingdom (UK), and
                                                          Switzerland
                                                          <bdt className="block-component" />
                                                          , you have rights that
                                                          allow you greater
                                                          access to and control
                                                          over your personal
                                                          information.
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <em>
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                </em>
                                                              </span>
                                                            </span>
                                                            &nbsp;
                                                          </span>
                                                          You may review,
                                                          change, or terminate
                                                          your account at any
                                                          time.
                                                        </em>
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <bdt className="block-component" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        In some regions (like{" "}
                                                        <bdt className="block-component" />
                                                        the EEA, UK, and
                                                        Switzerland
                                                        <bdt className="block-component" />
                                                        ), you have certain
                                                        rights under applicable
                                                        data protection laws.
                                                        These may include the
                                                        right (i) to request
                                                        access and obtain a copy
                                                        of your personal
                                                        information, (ii) to
                                                        request rectification or
                                                        erasure; (iii) to
                                                        restrict the processing
                                                        of your personal
                                                        information; (iv) if
                                                        applicable, to data
                                                        portability; and (v) not
                                                        to be subject to
                                                        automated
                                                        decision-making. In
                                                        certain circumstances,
                                                        you may also have the
                                                        right to object to the
                                                        processing of your
                                                        personal information.
                                                        You can make such a
                                                        request by contacting us
                                                        by using the contact
                                                        details provided in the
                                                        section{" "}
                                                        <bdt className="block-component" />
                                                        '
                                                        <bdt className="else-block" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                  <a
                                                    data-custom-class="link"
                                                    href="#contact"
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(0, 58, 250)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(0, 58, 250)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          HOW CAN YOU CONTACT US
                                                          ABOUT THIS NOTICE?
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </a>
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <bdt className="block-component" />
                                                        '
                                                        <bdt className="else-block" />{" "}
                                                        below.
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        We will consider and act
                                                        upon any request in
                                                        accordance with
                                                        applicable data
                                                        protection laws.
                                                        <bdt className="block-component" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    &nbsp;
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        If you are located in
                                                        the EEA or UK and you
                                                        believe we are
                                                        unlawfully processing
                                                        your personal
                                                        information, you also
                                                        have the right to
                                                        complain to your{" "}
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(0, 58, 250)",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(0, 58, 250)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <a
                                                                    data-custom-class="link"
                                                                    href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                    rel="noopener noreferrer"
                                                                    target="_blank"
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      Member
                                                                      State data
                                                                      protection
                                                                      authority
                                                                    </span>
                                                                  </a>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>{" "}
                                                        or&nbsp;
                                                      </span>
                                                    </span>
                                                  </span>
                                                  <a
                                                    data-custom-class="link"
                                                    href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(0, 58, 250)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(0, 58, 250)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          UK data protection
                                                          authority
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </a>
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        .
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        If you are located in
                                                        Switzerland, you may
                                                        contact the{" "}
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(0, 58, 250)",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(0, 58, 250)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(0, 58, 250)",
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <a
                                                                      data-custom-class="link"
                                                                      href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                      rel="noopener noreferrer"
                                                                      target="_blank"
                                                                    >
                                                                      Federal
                                                                      Data
                                                                      Protection
                                                                      and
                                                                      Information
                                                                      Commissioner
                                                                    </a>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        .
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  id="withdrawconsent"
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <strong>
                                                          <u>
                                                            Withdrawing your
                                                            consent:
                                                          </u>
                                                        </strong>{" "}
                                                        If we are relying on
                                                        your consent to process
                                                        your personal
                                                        information,
                                                        <bdt className="block-component" />
                                                        <bdt className="else-block" />{" "}
                                                        you have the right to
                                                        withdraw your consent at
                                                        any time. You can
                                                        withdraw your consent at
                                                        any time by contacting
                                                        us by using the contact
                                                        details provided in the
                                                        section{" "}
                                                        <bdt className="block-component" />
                                                        '
                                                        <bdt className="else-block" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                  <a
                                                    data-custom-class="link"
                                                    href="#contact"
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(0, 58, 250)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(0, 58, 250)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          HOW CAN YOU CONTACT US
                                                          ABOUT THIS NOTICE?
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </a>
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <bdt className="block-component" />
                                                        '
                                                        <bdt className="else-block" />{" "}
                                                        below
                                                        <bdt className="block-component" />
                                                        .
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      However, please note that
                                                      this will not affect the
                                                      lawfulness of the
                                                      processing before its
                                                      withdrawal nor,
                                                      <bdt className="block-component" />
                                                      <bdt className="else-block" />{" "}
                                                      will it affect the
                                                      processing of your
                                                      personal information
                                                      conducted in reliance on
                                                      lawful processing grounds
                                                      other than consent.
                                                      <bdt className="block-component" />
                                                    </span>
                                                  </span>
                                                  <bdt className="block-component">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                  <bdt className="block-component">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      If you have questions or
                                                      comments about your
                                                      privacy rights, you may
                                                      email us at{" "}
                                                      <bdt className="question">
                                                        aras0665yusuf@gmail.com
                                                      </bdt>
                                                      .
                                                    </span>
                                                  </span>
                                                  <bdt className="statement-end-if-in-editor">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  id="DNT"
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        "rgb(127, 127, 127)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            id="control"
                                                            style={{
                                                              color:
                                                                "rgb(0, 0, 0)",
                                                            }}
                                                          >
                                                            <strong>
                                                              <span data-custom-class="heading_1">
                                                                9. CONTROLS FOR
                                                                DO-NOT-TRACK
                                                                FEATURES
                                                              </span>
                                                            </strong>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        Most web browsers and
                                                        some mobile operating
                                                        systems and mobile
                                                        applications include a
                                                        Do-Not-Track (
                                                        <bdt className="block-component" />
                                                        'DNT'
                                                        <bdt className="else-block" />
                                                        ) feature or setting you
                                                        can activate to signal
                                                        your privacy preference
                                                        not to have data about
                                                        your online browsing
                                                        activities monitored and
                                                        collected. At this stage
                                                        no uniform technology
                                                        standard for{" "}
                                                        <bdt className="block-component" />
                                                        recognising
                                                        <bdt className="else-block" />{" "}
                                                        and implementing DNT
                                                        signals has been{" "}
                                                        <bdt className="block-component" />
                                                        finalised
                                                        <bdt className="else-block" />
                                                        . As such, we do not
                                                        currently respond to DNT
                                                        browser signals or any
                                                        other mechanism that
                                                        automatically
                                                        communicates your choice
                                                        not to be tracked
                                                        online. If a standard
                                                        for online tracking is
                                                        adopted that we must
                                                        follow in the future, we
                                                        will inform you about
                                                        that practice in a
                                                        revised version of this
                                                        privacy notice.
                                                        <bdt className="block-component" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                  <bdt className="block-component">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    />
                                                  </bdt>
                                                  <bdt className="block-component">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    />
                                                  </bdt>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  id="policyupdates"
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        "rgb(127, 127, 127)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            id="control"
                                                            style={{
                                                              color:
                                                                "rgb(0, 0, 0)",
                                                            }}
                                                          >
                                                            <strong>
                                                              <span data-custom-class="heading_1">
                                                                10. DO WE MAKE
                                                                UPDATES TO THIS
                                                                NOTICE?
                                                              </span>
                                                            </strong>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <em>
                                                          <strong>
                                                            In Short:&nbsp;
                                                          </strong>
                                                          Yes, we will update
                                                          this notice as
                                                          necessary to stay
                                                          compliant with
                                                          relevant laws.
                                                        </em>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        We may update this
                                                        privacy notice from time
                                                        to time. The updated
                                                        version will be
                                                        indicated by an updated{" "}
                                                        <bdt className="block-component" />
                                                        'Revised'
                                                        <bdt className="else-block" />{" "}
                                                        date and the updated
                                                        version will be
                                                        effective as soon as it
                                                        is accessible. If we
                                                        make material changes to
                                                        this privacy notice, we
                                                        may notify you either by
                                                        prominently posting a
                                                        notice of such changes
                                                        or by directly sending
                                                        you a notification. We
                                                        encourage you to review
                                                        this privacy notice
                                                        frequently to be
                                                        informed of how we are
                                                        protecting your
                                                        information.
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  id="contact"
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        "rgb(127, 127, 127)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            id="control"
                                                            style={{
                                                              color:
                                                                "rgb(0, 0, 0)",
                                                            }}
                                                          >
                                                            <strong>
                                                              <span data-custom-class="heading_1">
                                                                11. HOW CAN YOU
                                                                CONTACT US ABOUT
                                                                THIS NOTICE?
                                                              </span>
                                                            </strong>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        If you have questions or
                                                        comments about this
                                                        notice, you may{" "}
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component">
                                                              <bdt className="block-component">
                                                                <bdt className="block-component" />
                                                              </bdt>
                                                            </bdt>
                                                          </span>
                                                        </span>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              contact us by post
                                                              at:
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="question">
                                                                  Rscom
                                                                  Networking
                                                                  Ltd.
                                                                </bdt>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <bdt className="question">
                                                        Haylett Gardens,
                                                        Anglesea Road, Kingston
                                                        upon Thames, KT1 2EP
                                                      </bdt>
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <bdt className="question">
                                                        London
                                                      </bdt>
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <bdt className="block-component" />
                                                          ,{" "}
                                                          <bdt className="question">
                                                            Surrey
                                                          </bdt>
                                                          <bdt className="statement-end-if-in-editor" />
                                                          <bdt className="block-component" />{" "}
                                                          <bdt className="question">
                                                            KT1 2EP
                                                          </bdt>
                                                          <bdt className="statement-end-if-in-editor" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </span>
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <bdt className="question">
                                                        England
                                                      </bdt>
                                                    </span>
                                                  </span>
                                                  <bdt className="statement-end-if-in-editor">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                  <span
                                                    data-custom-class="body_text"
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <bdt className="question">
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <bdt className="else-block" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </bdt>
                                                    <bdt className="statement-end-if-in-editor" />
                                                  </span>
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <bdt className="statement-end-if-in-editor" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <bdt className="statement-end-if-in-editor">
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component">
                                                                    <bdt className="block-component" />
                                                                  </bdt>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <bdt className="block-component">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      />
                                                    </bdt>
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="statement-end-if-in-editor">
                                                                <bdt className="block-component" />
                                                              </bdt>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  id="request"
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      color:
                                                        "rgb(127, 127, 127)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                        fontSize: 15,
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            id="control"
                                                            style={{
                                                              color:
                                                                "rgb(0, 0, 0)",
                                                            }}
                                                          >
                                                            <strong>
                                                              <span data-custom-class="heading_1">
                                                                12. HOW CAN YOU
                                                                REVIEW, UPDATE,
                                                                OR DELETE THE
                                                                DATA WE COLLECT
                                                                FROM YOU?
                                                              </span>
                                                            </strong>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <br />
                                                </div>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <bdt className="block-component" />
                                                        Based on the applicable
                                                        laws of your country,
                                                        you may have the right
                                                        to request access to the
                                                        personal information we
                                                        collect from you, change
                                                        that information, or
                                                        delete it.{" "}
                                                        <bdt className="else-block">
                                                          <bdt className="block-component" />
                                                          To request to review,
                                                          update, or delete your
                                                          personal information,
                                                          please{" "}
                                                          <bdt className="block-component" />
                                                          fill out and submit
                                                          a&nbsp;
                                                        </bdt>
                                                      </span>
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(0, 58, 250)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(0, 58, 250)",
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <a
                                                              data-custom-class="link"
                                                              href="https://app.termly.io/notify/4e09e410-9f8c-4e8e-a4c9-8be480e881ca"
                                                              rel="noopener noreferrer"
                                                              target="_blank"
                                                            >
                                                              data subject
                                                              access request
                                                            </a>
                                                          </span>
                                                        </span>
                                                      </span>
                                                      <bdt className="block-component">
                                                        <span data-custom-class="body_text" />
                                                      </bdt>
                                                    </span>
                                                  </span>
                                                  <span data-custom-class="body_text">
                                                    .
                                                  </span>
                                                </div>
                                                <style
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      "\n      ul {\n        list-style-type: square;\n      }\n      ul > li > ul {\n        list-style-type: circle;\n      }\n      ul > li > ul > li > ul {\n        list-style-type: square;\n      }\n      ol li {\n        font-family: Arial ;\n      }\n    ",
                                                  }}
                                                />
                                              </div>
                                              <div
                                                style={{
                                                  color: "#595959",
                                                  fontSize: 14,
                                                  fontFamily: "Arial",
                                                  paddingTop: 16,
                                                }}
                                              >
                                                This privacy policy was created
                                                using Termly's{" "}
                                                <a
                                                  style={{
                                                    color:
                                                      "rgb(48, 48, 241) !important",
                                                  }}
                                                  href="https://termly.io/products/privacy-policy-generator/"
                                                >
                                                  Privacy Policy Generator
                                                </a>
                                                .
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
            </div>
          </div>
        </div>
      </>
  );
};

export default PrivacyPolicy;
